<template>
  <div class="container">
    <!-- 注册成功 顶部提示语 金额 按钮 -->
    <div class="con-top u-text-center">
      <img class="youcai-logo" src="../assets/images/logo.png" />

      <div class="u-flex u-row-center u-col-center">
        <img class="check-mark" src="../assets/images/icon_check_mark.png" />
        <span>注册成功，获得初审额度</span>
      </div>

      <div class="pr u-flex-col u-row-between lines">
        <div class="line" />
        <div class="line" />
        <div class="line" />
        <div class="line" />
        <count-to
          :start-val="0"
          :end-val="200000"
          :duration="3600"
          class="amount u-text-center pa"
        />
      </div>

      <button class="btn-submit" @click="handleSubmit">前往APP下载</button>
    </div>

    <div class="u-flex-col lists">
      <span class="tips" v-if="isFinsh">为您推荐今日优选</span>
      <div class="u-text-center" v-if="isFinsh">
        高额度放款 | 超低日息 | 闪电到账
      </div>
      <!-- 列表 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-if="isFinsh">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="product-item"
              @click="handleGoLink(item)"
            >
              <div class="u-flex u-col-center">
                <img class="product-logo" :src="item.imgUrl" />
                <span class="product-title">{{ item.productName }}</span>
              </div>

              <div class="product-item-middle u-flex u-row-between">
                <div class="product-item-middle-left">
                  <span class="number-span">{{ item.maxAmount }}</span>
                  <h5 class="product-tips">最高可借金额(元)</h5>
                </div>
                <div class="product-item-middle-center">
                  <span class="number-span"> {{ item.loanPeriod }}月 </span>
                  <h5 class="product-tips">借款期限</h5>
                </div>
                <div class="">
                  <span class="number-span">
                    {{ item.timeLimitLoanRate }}%
                  </span>
                  <h5 class="product-tips">综合年化</h5>
                </div>
              </div>

              <div class="u-flex u-col-center">
                <van-tag
                  v-for="(i, idx) in item.productTagList"
                  :key="idx"
                  color="#F9F7F2"
                  text-color="#B38E52"
                >
                  {{ i }}
                </van-tag>
              </div>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>
<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "ProductRecomed",
  data() {
    return {
      channel: this.$route.query.source,
      isFinsh: false, // 是否加载完成
      list: [],
      params: {
        pageNum: 1,
        pageSize: 5,
      },
      loading: false, // 是否处于加载状态，加载过程中不触发load事件
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: true, // 是否处于加载中状态
      total: 0, // 总共有多少条
      downAppUrl: "", // 下载地址
      showPop: false, // 分享蒙层状态
    };
  },
  created() {
    this.getDownloadUrl();
  },
  filters: {
    unitPrice(val) {
      if (val.indexOf(".") > -1) {
        return val.split(".")[0];
      } else {
        return val;
      }
    },
  },
  methods: {
    // 监听前往APP下载按钮点击
    handleSubmit() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        window.location.href = this.downAppUrl;
      }
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        }
      });
    },

    // 获取产品列表
    getProductList() {
      API_Common.queryProductList(this.params).then((res) => {
        if (200 === res.code) {
          if (res.data.productPageVO.list.length > 0) {
            this.total = res.data.productPageVO.total;
            this.params.pageNum++;

            this.list.push(...res.data.productPageVO.list);

            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
            this.isFinsh = true;
          } else {
            this.finished = true;
            this.isFinsh = false;
          }
        } else {
          this.$toast.fail("获取产品列表失败");
        }
      });
    },

    // 初始化加载列表数据
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
        this.params.pageNum = 1;
      }

      this.getProductList();
    },

    // 下拉刷新
    onRefresh() {
      this.finished = false; // 清空列表数据
      this.loading = true; // 重新加载数据 将 loading 设置为 true，表示处于加载状态
      this.onLoad();
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 跳转三方产品
    handleGoLink(item) {
      // 埋点
      API_Common.buried({
        productId: item.productId,
        showPage: 17,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(function () {
            window.location.href = item.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  background-color: #f1f2f6;
  overflow: auto;
}

.con-top {
  height: 6.6rem;
  background: url("../assets/images/product_recomed_bg.png") no-repeat center
    center;
  background-size: 100% 100%;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
}

.youcai-logo {
  width: 1.76rem;
  height: 1.76rem;
  margin: 0.42rem auto 0.44rem;
}

.lines {
  width: 3.92rem;
  height: 0.8rem;
  margin: 0.36rem auto 0.6rem;

  > .line {
    height: 0.02rem;
    opacity: 0.2;
    background: #ffffff;
  }
}

.check-mark {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.16rem;
}

.amount {
  width: 100%;
  top: -0.15rem;
  left: 0;
  font-size: 0.8rem;
  color: #ffffff;
}

.lists {
  padding: 0 0.4rem 0.2rem;
}

.btn-submit {
  width: 5.4rem;
  height: 0.96rem;
  background: #fff;
  border-radius: 0.48rem;
  display: block;
  font-size: 0.32rem;
  font-weight: 500;
  color: #3563fa;
  margin: 0 auto;
}

.tips {
  color: #111c31;
  margin: 0.35rem auto 0;
  font-size: 0.32rem;
  font-weight: 600;
}

.tips + div {
  font-size: 0.24rem;
  font-weight: 300;
  color: #868e9e;
  margin: 0.1rem 0 0.3rem;
}

.product-item {
  background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.32rem 0.28rem 0.3rem 0.3rem;
  margin-bottom: 0.24rem;
}

.product-logo {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 0.16rem;
  border-radius: 50%;
}

.product-title {
  font-size: 0.28rem;
  font-weight: 400;
  color: #111c31;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.product-item-middle {
  margin: -0.27rem 0 0.24rem;
}

.product-item-middle-left {
  color: #3563fa;
}

.number-span {
  font-size: 0.36rem;
  font-weight: 400;
  margin-right: -0.13rem;
  word-break: keep-all;
}

.product-item-middle-center {
  color: #3a3a3a;
}

.product-tips {
  font-size: 0.24rem;
  color: #868e9e;
  font-weight: 300;
}

.van-tag {
  margin-right: 0.1rem;
}

/* 下拉刷新样式重写 */
/deep/ {
  .van-pull-refresh__head,
  .van-list__loading,
  .van-loading__text,
  .van-loading__spinner,
  .van-list__finished-text {
    font-size: 0.28rem;
    color: #868e9e !important;
  }
}
</style>
